.rangeslider {
  -ms-touch-action: none;
  touch-action: none;
  background: #e6e6e6;
  margin: 20px 0;
  position: relative;
}

.rangeslider, .rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .4);
}

.rangeslider .rangeslider__handle {
  cursor: pointer;
  background: #fff;
  border: 1px solid #ccc;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .4), 0 -1px 3px rgba(0, 0, 0, .4);
}

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  transition: all .1s ease-in;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  line-height: 100%;
  display: inline-block;
}

.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  width: 0;
  height: 0;
  position: absolute;
}

.rangeslider-horizontal {
  border-radius: 10px;
  height: 12px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342;
  border-radius: 10px;
  height: 100%;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  background-color: #dadada;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  left: 6px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .4), inset 0 -1px 3px rgba(0, 0, 0, .4);
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-top: 8px solid rgba(0, 0, 0, .8);
  border-left: 8px solid rgba(0, 0, 0, 0);
  border-right: 8px solid rgba(0, 0, 0, 0);
  bottom: -8px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider-vertical {
  background-color: rgba(0, 0, 0, 0);
  max-width: 10px;
  height: 150px;
  margin: 20px auto;
}

.rangeslider-vertical .rangeslider__fill, .rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  box-shadow: none;
  background-color: #7cb342;
  width: 100%;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  box-shadow: none;
  width: 30px;
  height: 10px;
  left: -10px;
}

.rangeslider-vertical .rangeslider__handle-tooltip {
  top: 50%;
  left: -100%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid rgba(0, 0, 0, 0);
  border-left: 8px solid rgba(0, 0, 0, .8);
  top: 12px;
  left: 100%;
}

.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

.rangeslider__labels {
  position: relative;
}

.rangeslider-vertical .rangeslider__labels {
  text-align: left;
  width: 250px;
  height: 100%;
  margin: 0 0 0 24px;
  padding: 0;
  list-style-type: none;
  position: relative;
  left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item:before {
  content: "";
  z-index: -1;
  background: #000;
  width: 10px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: -14px;
  transform: translateY(-50%);
}

.rangeslider__labels .rangeslider__label-item {
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}

@font-face {
  font-family: Notification;
  src: url("notification.4adaffe7.eot");
  src: url("notification.4adaffe7.eot#iefixs3g3t9") format("embedded-opentype"), url("notification.5b25f080.woff") format("woff"), url("notification.93a9544c.ttf") format("truetype"), url("notification.a2e063d9.svg#notification") format("svg");
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  z-index: 999999;
  width: 320px;
  max-height: calc(100% - 30px);
  padding: 0 15px;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  opacity: .9;
  background-color: #ccc;
  border-radius: 2px;
  margin-top: 15px;
  padding: 15px 15px 15px 58px;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  box-shadow: 0 0 12px #999;
}

.notification .title {
  margin: 0 0 5px;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
}

.notification:hover, .notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transition: all .4s;
  transform: translate3d(0, 0, 0);
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transition: all .4s;
  transform: translate3d(100%, 0, 0);
}

.notification:before {
  text-align: center;
  width: 28px;
  height: 28px;
  margin-top: -14px;
  font-family: Notification;
  font-size: 28px;
  line-height: 28px;
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "";
}

.air-datepicker-cell.-day-.-other-month-, .air-datepicker-cell.-year-.-other-decade- {
  color: var(--adp-color-other-month);
}

.air-datepicker-cell.-day-.-other-month-:hover, .air-datepicker-cell.-year-.-other-decade-:hover {
  color: var(--adp-color-other-month-hover);
}

.-disabled-.-focus-.air-datepicker-cell.-day-.-other-month-, .-disabled-.-focus-.air-datepicker-cell.-year-.-other-decade- {
  color: var(--adp-color-other-month);
}

.-selected-.air-datepicker-cell.-day-.-other-month-, .-selected-.air-datepicker-cell.-year-.-other-decade- {
  color: #fff;
  background: var(--adp-background-color-selected-other-month);
}

.-selected-.-focus-.air-datepicker-cell.-day-.-other-month-, .-selected-.-focus-.air-datepicker-cell.-year-.-other-decade- {
  background: var(--adp-background-color-selected-other-month-focused);
}

.-in-range-.air-datepicker-cell.-day-.-other-month-, .-in-range-.air-datepicker-cell.-year-.-other-decade- {
  background-color: var(--adp-background-color-in-range);
  color: var(--adp-color);
}

.-in-range-.-focus-.air-datepicker-cell.-day-.-other-month-, .-in-range-.-focus-.air-datepicker-cell.-year-.-other-decade- {
  background-color: var(--adp-background-color-in-range-focused);
}

.air-datepicker-cell.-day-.-other-month-:empty, .air-datepicker-cell.-year-.-other-decade-:empty {
  background: none;
  border: none;
}

.air-datepicker-cell {
  border-radius: var(--adp-cell-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.air-datepicker-cell.-focus- {
  background: var(--adp-background-color-hover);
}

.air-datepicker-cell.-current- {
  color: var(--adp-color-current-date);
}

.air-datepicker-cell.-current-.-focus- {
  color: var(--adp-color);
}

.air-datepicker-cell.-current-.-in-range- {
  color: var(--adp-color-current-date);
}

.air-datepicker-cell.-disabled- {
  cursor: default;
  color: var(--adp-color-disabled);
}

.air-datepicker-cell.-disabled-.-focus- {
  color: var(--adp-color-disabled);
}

.air-datepicker-cell.-disabled-.-in-range- {
  color: var(--adp-color-disabled-in-range);
}

.air-datepicker-cell.-disabled-.-current-.-focus- {
  color: var(--adp-color-disabled);
}

.air-datepicker-cell.-in-range- {
  background: var(--adp-cell-background-color-in-range);
  border-radius: 0;
}

.air-datepicker-cell.-in-range-:hover {
  background: var(--adp-cell-background-color-in-range-hover);
}

.air-datepicker-cell.-range-from- {
  border: 1px solid var(--adp-cell-border-color-in-range);
  background-color: var(--adp-cell-background-color-in-range);
  border-radius: var(--adp-cell-border-radius) 0 0 var(--adp-cell-border-radius);
}

.air-datepicker-cell.-range-to- {
  border: 1px solid var(--adp-cell-border-color-in-range);
  background-color: var(--adp-cell-background-color-in-range);
  border-radius: 0 var(--adp-cell-border-radius) var(--adp-cell-border-radius) 0;
}

.air-datepicker-cell.-range-to-.-range-from- {
  border-radius: var(--adp-cell-border-radius);
}

.air-datepicker-cell.-selected- {
  color: #fff;
  background: var(--adp-cell-background-color-selected);
  border: none;
}

.air-datepicker-cell.-selected-.-current- {
  color: #fff;
  background: var(--adp-cell-background-color-selected);
}

.air-datepicker-cell.-selected-.-focus- {
  background: var(--adp-cell-background-color-selected-hover);
}

.air-datepicker-body {
  transition: all var(--adp-transition-duration) var(--adp-transition-ease);
}

.air-datepicker-body.-hidden- {
  display: none;
}

.air-datepicker-body--day-names {
  display: -ms-grid;
  -ms-grid-columns: (var(--adp-day-cell-width)) [7];
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  margin: 8px 0 3px;
  display: grid;
}

.air-datepicker-body--day-name {
  color: var(--adp-day-name-color);
  text-align: center;
  text-transform: uppercase;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: .8em;
  display: -ms-flexbox;
  display: flex;
}

.air-datepicker-body--cells {
  display: -ms-grid;
  display: grid;
}

.air-datepicker-body--cells.-days- {
  -ms-grid-columns: (var(--adp-day-cell-width)) [7];
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  grid-auto-rows: var(--adp-day-cell-height);
}

.air-datepicker-body--cells.-months- {
  -ms-grid-columns: (1fr) [3];
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: var(--adp-month-cell-height);
}

.air-datepicker-body--cells.-years- {
  -ms-grid-columns: (1fr) [4];
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: var(--adp-year-cell-height);
}

.air-datepicker-nav {
  border-bottom: 1px solid var(--adp-border-color-inner);
  min-height: var(--adp-nav-height);
  padding: var(--adp-padding);
  box-sizing: content-box;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

.-only-timepicker- .air-datepicker-nav {
  display: none;
}

.air-datepicker-nav--title, .air-datepicker-nav--action {
  cursor: pointer;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.air-datepicker-nav--action {
  width: var(--adp-nav-action-size);
  border-radius: var(--adp-border-radius);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.air-datepicker-nav--action:hover {
  background: var(--adp-background-color-hover);
}

.air-datepicker-nav--action:active {
  background: var(--adp-background-color-active);
}

.air-datepicker-nav--action.-disabled- {
  visibility: hidden;
}

.air-datepicker-nav--action svg {
  width: 32px;
  height: 32px;
}

.air-datepicker-nav--action path {
  fill: none;
  stroke: var(--adp-nav-arrow-color);
  stroke-width: 2px;
}

.air-datepicker-nav--title {
  border-radius: var(--adp-border-radius);
  padding: 0 8px;
}

.air-datepicker-nav--title i {
  color: var(--adp-nav-color-secondary);
  margin-left: .3em;
  font-style: normal;
}

.air-datepicker-nav--title:hover {
  background: var(--adp-background-color-hover);
}

.air-datepicker-nav--title:active {
  background: var(--adp-background-color-active);
}

.air-datepicker-nav--title.-disabled- {
  cursor: default;
  background: none;
}

.air-datepicker-buttons {
  display: -ms-grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

.air-datepicker-button {
  color: var(--adp-btn-color);
  border-radius: var(--adp-btn-border-radius);
  cursor: pointer;
  height: var(--adp-btn-height);
  background: rgba(255, 255, 255, 0);
  border: none;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.air-datepicker-button:hover {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
}

.air-datepicker-button:focus {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
  outline: none;
}

.air-datepicker-button:active {
  background: var(--adp-btn-background-color-active);
}

.air-datepicker-button span {
  outline: none;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
}

.air-datepicker-time {
  display: -ms-grid;
  -ms-grid-columns: max-content 1fr;
  grid-column-gap: 12px;
  padding: 0 var(--adp-time-padding-inner);
  grid-template-columns: max-content 1fr;
  -ms-flex-align: center;
  align-items: center;
  display: grid;
  position: relative;
}

.-only-timepicker- .air-datepicker-time {
  border-top: none;
}

.air-datepicker-time--current {
  text-align: center;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
}

.air-datepicker-time--current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.air-datepicker-time--current-hours, .air-datepicker-time--current-minutes {
  z-index: 1;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-size: 19px;
  line-height: 1;
  position: relative;
}

.air-datepicker-time--current-hours:after, .air-datepicker-time--current-minutes:after {
  content: "";
  background: var(--adp-background-color-hover);
  border-radius: var(--adp-border-radius);
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: -3px;
  bottom: -2px;
  left: -2px;
  right: -2px;
}

.air-datepicker-time--current-hours.-focus-:after, .air-datepicker-time--current-minutes.-focus-:after {
  opacity: 1;
}

.air-datepicker-time--current-ampm {
  text-transform: uppercase;
  color: var(--adp-time-day-period-color);
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: 1px;
  margin-left: 6px;
  font-size: 11px;
}

.air-datepicker-time--row {
  background: linear-gradient(to right, var(--adp-time-track-color), var(--adp-time-track-color)) left 50% / 100% var(--adp-time-track-height) no-repeat;
  -ms-flex-align: center;
  align-items: center;
  height: 17px;
  font-size: 11px;
  display: -ms-flexbox;
  display: flex;
}

.air-datepicker-time--row:first-child {
  margin-bottom: 4px;
}

.air-datepicker-time--row input[type="range"] {
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  margin: 0;
  padding: 0;
}

.air-datepicker-time--row input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.air-datepicker-time--row input[type="range"]::-ms-tooltip {
  display: none;
}

.air-datepicker-time--row input[type="range"]:hover::-webkit-slider-thumb {
  border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type="range"]:hover::-moz-range-thumb {
  border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type="range"]:hover::-ms-thumb {
  border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type="range"]:focus {
  outline: none;
}

.air-datepicker-time--row input[type="range"]:focus::-webkit-slider-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type="range"]:focus::-moz-range-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type="range"]:focus::-ms-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type="range"]::-webkit-slider-thumb {
  box-sizing: border-box;
  border: 1px solid var(--adp-time-track-color);
  cursor: pointer;
  transition: background var(--adp-transition-duration);
  background: #fff;
  border-radius: 3px;
  width: 12px;
  height: 12px;
}

.air-datepicker-time--row input[type="range"]::-moz-range-thumb {
  box-sizing: border-box;
  border: 1px solid var(--adp-time-track-color);
  cursor: pointer;
  transition: background var(--adp-transition-duration);
  background: #fff;
  border-radius: 3px;
  width: 12px;
  height: 12px;
}

.air-datepicker-time--row input[type="range"]::-ms-thumb {
  box-sizing: border-box;
  border: 1px solid var(--adp-time-track-color);
  cursor: pointer;
  transition: background var(--adp-transition-duration);
  background: #fff;
  border-radius: 3px;
  width: 12px;
  height: 12px;
}

.air-datepicker-time--row input[type="range"]::-webkit-slider-thumb {
  margin-top: calc(var(--adp-time-thumb-size) / 2 * -1);
}

.air-datepicker-time--row input[type="range"]::-webkit-slider-runnable-track {
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  background: none;
  border: none;
}

.air-datepicker-time--row input[type="range"]::-moz-range-track {
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  background: none;
  border: none;
}

.air-datepicker-time--row input[type="range"]::-ms-track {
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  background: none;
  border: none;
}

.air-datepicker-time--row input[type="range"]::-ms-fill-lower {
  background: none;
}

.air-datepicker-time--row input[type="range"]::-ms-fill-upper {
  background: none;
}

.air-datepicker {
  --adp-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --adp-font-size: 14px;
  --adp-width: 246px;
  --adp-z-index: 100;
  --adp-padding: 4px;
  --adp-grid-areas: "nav" "body" "timepicker" "buttons";
  --adp-transition-duration: .3s;
  --adp-transition-ease: ease-out;
  --adp-transition-offset: 8px;
  --adp-background-color: #fff;
  --adp-background-color-hover: #f0f0f0;
  --adp-background-color-active: #eaeaea;
  --adp-background-color-in-range: rgba(92, 196, 239, .1);
  --adp-background-color-in-range-focused: rgba(92, 196, 239, .2);
  --adp-background-color-selected-other-month-focused: #8ad5f4;
  --adp-background-color-selected-other-month: #a2ddf6;
  --adp-color: #4a4a4a;
  --adp-color-secondary: #9c9c9c;
  --adp-accent-color: #4eb5e6;
  --adp-color-current-date: var(--adp-accent-color);
  --adp-color-other-month: #dedede;
  --adp-color-disabled: #aeaeae;
  --adp-color-disabled-in-range: #939393;
  --adp-color-other-month-hover: #c5c5c5;
  --adp-border-color: #dbdbdb;
  --adp-border-color-inner: #efefef;
  --adp-border-radius: 4px;
  --adp-border-color-inline: #d7d7d7;
  --adp-nav-height: 32px;
  --adp-nav-arrow-color: var(--adp-color-secondary);
  --adp-nav-action-size: 32px;
  --adp-nav-color-secondary: var(--adp-color-secondary);
  --adp-day-name-color: #ff9a19;
  --adp-day-cell-width: 1fr;
  --adp-day-cell-height: 32px;
  --adp-month-cell-height: 42px;
  --adp-year-cell-height: 56px;
  --adp-pointer-size: 10px;
  --adp-poiner-border-radius: 2px;
  --adp-pointer-offset: 14px;
  --adp-cell-border-radius: 4px;
  --adp-cell-background-color-selected: #5cc4ef;
  --adp-cell-background-color-selected-hover: #45bced;
  --adp-cell-background-color-in-range: rgba(92, 196, 239, .1);
  --adp-cell-background-color-in-range-hover: rgba(92, 196, 239, .2);
  --adp-cell-border-color-in-range: var(--adp-cell-background-color-selected);
  --adp-btn-height: 32px;
  --adp-btn-color: var(--adp-accent-color);
  --adp-btn-color-hover: var(--adp-color);
  --adp-btn-border-radius: var(--adp-border-radius);
  --adp-btn-background-color-hover: var(--adp-background-color-hover);
  --adp-btn-background-color-active: var(--adp-background-color-active);
  --adp-time-track-height: 1px;
  --adp-time-track-color: #dedede;
  --adp-time-track-color-hover: #b1b1b1;
  --adp-time-thumb-size: 12px;
  --adp-time-padding-inner: 10px;
  --adp-time-day-period-color: var(--adp-color-secondary);
  --adp-mobile-font-size: 16px;
  --adp-mobile-nav-height: 40px;
  --adp-mobile-width: 320px;
  --adp-mobile-day-cell-height: 38px;
  --adp-mobile-month-cell-height: 48px;
  --adp-mobile-year-cell-height: 64px;
}

.air-datepicker-overlay {
  --adp-overlay-background-color: rgba(0, 0, 0, .3);
  --adp-overlay-transition-duration: .3s;
  --adp-overlay-transition-ease: ease-out;
  --adp-overlay-z-index: 100;
}

.air-datepicker {
  background: var(--adp-background-color);
  border: 1px solid var(--adp-border-color);
  border-radius: var(--adp-border-radius);
  box-sizing: content-box;
  display: -ms-grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: (max-content) [4];
  grid-template-rows: repeat(4, max-content);
  grid-template-columns: 1fr;
  grid-template-areas: var(--adp-grid-areas);
  font-family: var(--adp-font-family), sans-serif;
  font-size: var(--adp-font-size);
  color: var(--adp-color);
  width: var(--adp-width);
  transition: opacity var(--adp-transition-duration) var(--adp-transition-ease), transform var(--adp-transition-duration) var(--adp-transition-ease);
  z-index: var(--adp-z-index);
  display: grid;
  position: absolute;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
}

.air-datepicker:not(.-custom-position-) {
  opacity: 0;
}

.air-datepicker.-from-top- {
  transform: translateY(calc(var(--adp-transition-offset) * -1));
}

.air-datepicker.-from-right- {
  transform: translateX(var(--adp-transition-offset));
}

.air-datepicker.-from-bottom- {
  transform: translateY(var(--adp-transition-offset));
}

.air-datepicker.-from-left- {
  transform: translateX(calc(var(--adp-transition-offset) * -1));
}

.air-datepicker.-active-:not(.-custom-position-) {
  opacity: 1;
  transform: translate(0);
}

.air-datepicker.-active-.-custom-position- {
  transition: none;
}

.air-datepicker.-inline- {
  border-color: var(--adp-border-color-inline);
  box-shadow: none;
  opacity: 1;
  position: static;
  left: auto;
  right: auto;
  transform: none;
}

.air-datepicker.-inline- .air-datepicker--pointer {
  display: none;
}

.air-datepicker.-is-mobile- {
  --adp-font-size: var(--adp-mobile-font-size);
  --adp-day-cell-height: var(--adp-mobile-day-cell-height);
  --adp-month-cell-height: var(--adp-mobile-month-cell-height);
  --adp-year-cell-height: var(--adp-mobile-year-cell-height);
  --adp-nav-height: var(--adp-mobile-nav-height);
  --adp-nav-action-size: var(--adp-mobile-nav-height);
  width: var(--adp-mobile-width);
  border: none;
  position: fixed;
}

.air-datepicker.-is-mobile- * {
  -webkit-tap-highlight-color: transparent;
}

.air-datepicker.-is-mobile- .air-datepicker--pointer {
  display: none;
}

.air-datepicker.-is-mobile-:not(.-custom-position-) {
  transform: translate(-50%, calc(-50% + var(--adp-transition-offset)));
}

.air-datepicker.-is-mobile-.-active-:not(.-custom-position-) {
  transform: translate(-50%, -50%);
}

.air-datepicker.-custom-position- {
  transition: none;
}

.air-datepicker-global-container {
  position: absolute;
  top: 0;
  left: 0;
}

.air-datepicker--pointer {
  --pointer-half-size: calc(var(--adp-pointer-size) / 2);
  width: var(--adp-pointer-size);
  height: var(--adp-pointer-size);
  z-index: -1;
  position: absolute;
}

.air-datepicker--pointer:after {
  content: "";
  border-top: 1px solid var(--adp-border-color-inline);
  border-right: 1px solid var(--adp-border-color-inline);
  border-top-right-radius: var(--adp-poiner-border-radius);
  width: var(--adp-pointer-size);
  height: var(--adp-pointer-size);
  box-sizing: border-box;
  background: #fff;
  position: absolute;
}

.-top-left- .air-datepicker--pointer, .-top-center- .air-datepicker--pointer, .-top-right- .air-datepicker--pointer, [data-popper-placement^="top"] .air-datepicker--pointer {
  top: calc(100% - var(--pointer-half-size)  + 1px);
}

.-top-left- .air-datepicker--pointer:after, .-top-center- .air-datepicker--pointer:after, .-top-right- .air-datepicker--pointer:after, [data-popper-placement^="top"] .air-datepicker--pointer:after {
  transform: rotate(135deg);
}

.-right-top- .air-datepicker--pointer, .-right-center- .air-datepicker--pointer, .-right-bottom- .air-datepicker--pointer, [data-popper-placement^="right"] .air-datepicker--pointer {
  right: calc(100% - var(--pointer-half-size)  + 1px);
}

.-right-top- .air-datepicker--pointer:after, .-right-center- .air-datepicker--pointer:after, .-right-bottom- .air-datepicker--pointer:after, [data-popper-placement^="right"] .air-datepicker--pointer:after {
  transform: rotate(225deg);
}

.-bottom-left- .air-datepicker--pointer, .-bottom-center- .air-datepicker--pointer, .-bottom-right- .air-datepicker--pointer, [data-popper-placement^="bottom"] .air-datepicker--pointer {
  bottom: calc(100% - var(--pointer-half-size)  + 1px);
}

.-bottom-left- .air-datepicker--pointer:after, .-bottom-center- .air-datepicker--pointer:after, .-bottom-right- .air-datepicker--pointer:after, [data-popper-placement^="bottom"] .air-datepicker--pointer:after {
  transform: rotate(315deg);
}

.-left-top- .air-datepicker--pointer, .-left-center- .air-datepicker--pointer, .-left-bottom- .air-datepicker--pointer, [data-popper-placement^="left"] .air-datepicker--pointer {
  left: calc(100% - var(--pointer-half-size)  + 1px);
}

.-left-top- .air-datepicker--pointer:after, .-left-center- .air-datepicker--pointer:after, .-left-bottom- .air-datepicker--pointer:after, [data-popper-placement^="left"] .air-datepicker--pointer:after {
  transform: rotate(45deg);
}

.-top-left- .air-datepicker--pointer, .-bottom-left- .air-datepicker--pointer {
  left: var(--adp-pointer-offset);
}

.-top-right- .air-datepicker--pointer, .-bottom-right- .air-datepicker--pointer {
  right: var(--adp-pointer-offset);
}

.-top-center- .air-datepicker--pointer, .-bottom-center- .air-datepicker--pointer {
  left: calc(50% - var(--adp-pointer-size) / 2);
}

.-left-top- .air-datepicker--pointer, .-right-top- .air-datepicker--pointer {
  top: var(--adp-pointer-offset);
}

.-left-bottom- .air-datepicker--pointer, .-right-bottom- .air-datepicker--pointer {
  bottom: var(--adp-pointer-offset);
}

.-left-center- .air-datepicker--pointer, .-right-center- .air-datepicker--pointer {
  top: calc(50% - var(--adp-pointer-size) / 2);
}

.air-datepicker--navigation {
  grid-area: nav;
}

.air-datepicker--content {
  box-sizing: content-box;
  padding: var(--adp-padding);
  grid-area: body;
}

.-only-timepicker- .air-datepicker--content {
  display: none;
}

.air-datepicker--time {
  grid-area: timepicker;
}

.air-datepicker--buttons {
  grid-area: buttons;
}

.air-datepicker--buttons, .air-datepicker--time {
  padding: var(--adp-padding);
  border-top: 1px solid var(--adp-border-color-inner);
}

.air-datepicker-overlay {
  background: var(--adp-overlay-background-color);
  opacity: 0;
  transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), left 0s, height 0s, width 0s;
  transition-delay: 0s, var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration);
  z-index: var(--adp-overlay-z-index);
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.air-datepicker-overlay.-active- {
  opacity: 1;
  transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), height 0s, width 0s;
  width: 100%;
  height: 100%;
}



/*# sourceMappingURL=app-df6d4a64.css.map */
